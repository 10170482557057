import {
  Modal,
  ModalBody,
  ModalClose,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalProps,
} from '@/components/ui/Modal';
import Heading from '@/components/ui/Heading';
import routes from '@/router/routes';
import useLocalStorageState from 'use-local-storage-state';
import {useRouter} from 'next/navigation';
import Button from '@/components/ui/Button';
import styles from './styles.module.css';
import Link from 'next/link';
import { SignInButton, SignUpButton } from '@clerk/nextjs';

const SignOutUserModal = (props: ModalProps) => {
  const router = useRouter();
  const [, setRedirectUrl] = useLocalStorageState<string>('REDIRECT_URL');

  const handleLoginRedirect = () => {
    setRedirectUrl(window.location.pathname);
    router.push(routes.TO_SIGN_IN);
  };

  return (
    <Modal {...props}>
      <ModalContent className={styles.modalContent}>
        <ModalHeader>
          <ModalTitle>Log in</ModalTitle>
          <ModalClose />
        </ModalHeader>
        <ModalBody>
          <Heading size="h6" weight="bold" as="h2">
            You must be logged in to schedule showings on Offerwell.
          </Heading>
        </ModalBody>
        <ModalFooter>

        <SignUpButton mode="modal">
          <Button size="small">
            Register Free
          </Button>
        </SignUpButton>
        <SignInButton mode="modal">
          <Button variant="secondary_mono_dark" size="small">
            Sign In
          </Button>
        </SignInButton>


        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default SignOutUserModal;

/*
          <Button
            size="small"
            variant="secondary_mono_dark"
            onClick={handleLoginRedirect}
            className={styles.btn}>
            Sign Up
          </Button>
          <SignInButton></SignInButton>
          <Button
            size="small"
            variant="primary_accent_dark"
            className={styles.btn}
            asChild>
              
            <Link href="/auth/signUp">Log In</Link>
          </Button>

*/
