import {TColorsBorder} from '@/themes';
import styled from 'styled-components';
import {NewBrand} from '@/themes/palletes/types';

type TProps = {
  border?: keyof TColorsBorder;
  type: boolean;
};
type LProps = {isLoading: boolean};

export const Animation = styled.div<LProps>`
  @keyframes skeleton-loading {
    0% {
      background-color: ${({theme}) => theme.new_brand.monochrome_90};
    }

    100% {
      background-color: ${({theme}) => theme.new_brand.monochrome_80};
    }
  }

  animation: ${({isLoading}) => (isLoading ? 'skeleton-loading' : '')} 1s linear
    infinite alternate;
  border-radius: 16px;
`;

export const Wrapper = styled.div<TProps>`
  display: flex;
  flex-direction: column;
  background-color: ${({theme}) => theme.new_brand.monochrome_100};
  border: 1px solid ${({theme}) => theme.new_brand.monochrome_75};
  position: relative;

  &:hover {
    transform: scale(1.02) translate(0, 2px);
  }

  transition:
  box-shadow 0.2s,
  transform 0.2s;
  border-radius: 16px;
  cursor: pointer;
`;

export const Img = styled.img<{img: string; isLoading: boolean}>`
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 16px 16px 0 0;
  background-image: url(${({img}) => img});
  background-position: center;
  background-size: cover;

  @keyframes skeleton-loading {
    0% {
      background-color: ${({theme}) => theme.new_brand.monochrome_90};
    }

    100% {
      background-color: ${({theme}) => theme.new_brand.monochrome_80};
    }
  }

  animation: skeleton-loading 1s linear 8 alternate;
`;

export const InfoWrapper = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 246px;

  ${({theme}) => theme.mediaQueriesMax.xs} {
    padding: 16px;
    min-height: 228px;
  }
`;

export const MainInfo = styled.div`
  margin-bottom: 20px;
`;

export const StreetText = styled(Animation)`
  font-weight: 700;
  line-height: 24px;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 95%;
  white-space: nowrap;
  color: ${({isLoading}) => (isLoading ? 'transparent' : '')};
`;

export const PriceWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap;
`;

export const PriceTypeWrapper = styled(Animation)`
  margin-right: 24px;
  color: ${({isLoading}) => (isLoading ? 'transparent' : '')} !important;
`;

export const PriceSubtitle = styled.div<{error?: boolean; isLoading: boolean}>`
  font-weight: 500;
  font-size: 12px;
  color: ${({theme, error, isLoading}) =>
    isLoading
      ? 'transparent'
      : theme.new_brand[error ? 'danger_40' : 'monochrome_50']};
  margin: 8px 0 1px;
`;

export const Price = styled.div<{isLoading: boolean}>`
  font-weight: 700;
  font-size: 20px;
  color: ${({isLoading}) => (isLoading ? 'transparent' : '')};

  @media screen and (max-width: 1270px) {
    font-size: 16px;
  }
`;

export const ImageWrapper = styled.div`
  height: 225px;
  width: 100%;
  border-radius: 16px 16px 0 0;
  position: relative;
  overflow: hidden;
`;
export const StatusWrapper = styled.div`
  position: absolute;
  top: 24px;
  left: 24px;
  right: 24px;
  flex-wrap: wrap;
  display: flex;
  align-items: flex-start;
`;

const getStatusColors = (
  type: string,
): {primary: keyof NewBrand; secondary: keyof NewBrand} => {
  switch (type) {
    case 'Pending':
      return {primary: 'neutral_80', secondary: 'neutral_0'};
    case 'Withdrawn':
      return {primary: 'primary_10', secondary: 'primary_10'};
    case 'Closed':
      return {primary: 'monochrome_95', secondary: 'monochrome_5'};
    default:
      return {primary: 'brand_yellow', secondary: 'brand_green'};
  }
};

export const Dot = styled.div<{type: string}>`
  width: 7px;
  height: 7px;
  margin-right: 8px;
  border-radius: 100%;
  background-color: ${({theme, type}) =>
    theme.new_brand[getStatusColors(type).secondary]};
`;
export const Status = styled.div<{type: string}>`
  background-color: ${({theme, type}) =>
    theme.new_brand[getStatusColors(type).primary]};

  & > div {
    color: ${({theme, type}) =>
      theme.new_brand[getStatusColors(type).secondary]};
  }

  padding: 8px 16px;
  border-radius: 40px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  height: 35px;
`;

export const TopInfo = styled.div`
  border-radius: 8px;
  background-color: ${({theme}) => theme.new_brand.warning_80};
  padding: 4px 8px;
  font-weight: 500;
  font-size: 12px;
  line-height: 140%;
  height: 35px;
  display: flex;
  align-items: center;
`;

export const DaysOnMarketInfo = styled(TopInfo)`
  margin: 0 8px;
`;

export const AgentName = styled(Animation)`
  font-weight: 700;
  font-size: 16px;
  line-height: 140%;
  color: ${({theme, isLoading}) =>
    isLoading ? 'transparent' : theme.new_brand.monochrome_50};
  width: ${({isLoading}) => isLoading && '40%'};
`;

export const BrokerInfo = styled(Animation)`
  font-weight: 400;
  font-size: 12px;
  color: ${({theme, isLoading}) =>
    isLoading ? 'transparent' : theme.new_brand.monochrome_70};
  margin-bottom: 16px;
  width: 95%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const AnimationBlock = styled(Animation)`
  width: 100%;
  border-radius: 40px;
  min-height: 44px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: ${({theme}) => theme.spacing.desktop.element.gap.medium}px;
`;
