import {FontWeights} from '@/themes';
import {ButtonKeys} from '@/themes/palletes/types';
import styled from 'styled-components';

type TType = 'outline' | 'default';

type TWrapperProps = {
  color: keyof ButtonKeys;

  margin: {
    top: string;
    left: string;
    right: string;
    bottom: string;
  };

  type: TType;

  borderRadius: number;
  width?: string;
  height: number;

  isDisabled: boolean;
};

type TTextProps = {
  type: TType;

  weight: keyof typeof FontWeights;
  fontSize: number;
  align?: 'center' | 'left' | 'right';

  uppercase?: boolean;

  color: keyof ButtonKeys;
};

const isBackground = (type: TType) => type === 'default';

export const Wrapper = styled.a<TWrapperProps>`
  display: flex;
  align-items: center;
  text-decoration: none;
  min-height: ${({height}) => height}px;
  width: ${({width}) => width};
  border: 1px solid ${({color, theme}) => theme.button.border[color]};
  border-radius: ${({borderRadius}) => borderRadius}px;
  background: ${({color, theme, type}) =>
    isBackground(type) ? theme.button.background[color] : 'transparent'};
  opacity: ${({isDisabled}) => (isDisabled ? 0.5 : 1)};
  pointer-events: ${({isDisabled}) => isDisabled && 'none'};
  margin: ${({margin: {right, bottom, left, top}}) =>
    `${top} ${right} ${bottom} ${left}`};

  &:hover {
    background: ${({color, theme, type}) =>
      isBackground(type) ? theme.button.hover[color] : 'transparent'};
    border: 1px solid ${({color, theme}) => theme.button.hover_border[color]};
  }

  padding: 0 16px;
`;
export const Text = styled.span<TTextProps>`
  color: ${({theme, color, type}) =>
    theme.button[isBackground(type) ? 'text' : 'background'][color]};
  font-weight: ${({weight}) => (weight == 'bold' ? 700 : FontWeights[weight])};
  font-size: ${({fontSize}) => fontSize}px;
  text-transform: ${({uppercase}) => (uppercase ? 'uppercase' : 'none')};
  text-align: ${({align}) => align};
  white-space: nowrap;
  user-select: none;
`;

export const IconWrapper = styled.div<{left?: boolean}>`
  display: flex;
  align-items: center;
  margin-left: ${({left}) => (left ? '0px' : '8px')};
`;
export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
