import {FC} from 'react';
import {Notification} from '@/features/notifications/types';
import {format, formatDistanceToNow, isToday} from 'date-fns';
import Text from '@/components/ui/Text';
import {Check, Checks} from '@phosphor-icons/react/dist/ssr';
import styles from './styles.module.css';
import {cx} from 'class-variance-authority';

type TProps = {
  item: Notification;
  onMarkSeen: (id: number) => void;
};

export const NotificationCard: FC<TProps> = ({item, onMarkSeen}) => {
  const notificationDate = new Date(item.datetime_created);
  const maybeRelativeDate = isToday(notificationDate)
    ? formatDistanceToNow(notificationDate, {
        addSuffix: true,
      })
    : format(notificationDate, 'E MMM dd yyyy');

  return (
    <div className={styles.wrapper}>
      <div className={styles.row}>
        {item.data.prop_address ? (
          <Text className={styles.address} color="font_color_subdued">
            {item.data.prop_address}
          </Text>
        ) : null}
        <Text className={styles.timeText} color="font_color_subdued">
          {!item.is_viewed && <div className={styles.circle} />}
          {maybeRelativeDate}
        </Text>
      </div>
      <div className={styles.row}>
        {item.title ? (
          <Text
            className={styles.title}
            size="p_medium"
            weight={item.is_viewed ? 'medium' : 'bold'}>
            {item.title}
          </Text>
        ) : null}
        <button
          aria-label="Mark as read"
          className={cx(styles.actionBtn, item.is_viewed && styles.viewed)}
          id="child"
          onClick={(event) => {
            event.stopPropagation();
            onMarkSeen(item.id);
          }}>
          {item.is_viewed ? <Checks size={20} /> : <Check />}
        </button>
      </div>
    </div>
  );
};
