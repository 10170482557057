import {FC, useState, Fragment} from 'react';
import {
  AgentName,
  AnimationBlock,
  BrokerInfo,
  ButtonsWrapper,
  DaysOnMarketInfo,
  Dot,
  ImageWrapper,
  Img,
  InfoWrapper,
  MainInfo,
  Price,
  PriceSubtitle,
  PriceTypeWrapper,
  PriceWrapper,
  Status,
  StatusWrapper,
  StreetText,
  TopInfo,
  Wrapper,
} from './GridAllHouseCard.styles';
import free from '@/assets/images/Dashboard/free.png';
import {ListingItem} from '@/features/types';
import {differenceInDays} from 'date-fns';
import SignOutUserModal from '@/features/showings/components/Modals/SignOutUserModal';

import { SignedIn, SignedOut, SignUpButton } from '@clerk/nextjs';

import Button from '@/components/ui/Button';
import {formatPrice} from '@/helpers/formatPrice';
import {useRouter} from 'next/navigation';

type TProps = {
  item: ListingItem;
  type: number;
  is_agent_profile?: boolean;
  isLoading?: boolean;
};

export const GridAllHouseCard: FC<TProps> = ({
  item,
  type,
  is_agent_profile,
  isLoading,
}) => {
  const router = useRouter();

  const [signOutModal, setSignOutModal] = useState(false);
  const days = differenceInDays(
    new Date(Date.now()),
    new Date(item.date_created),
  );

  const showingsIsActive = () => {
    if (!item?.showing_details?.accept_showings) {
      return false;
    }

    return (
      item.showing_details.status !== 1 && item.std_status_display == 'Active'
    );
  };

  return (
    <>
      <Wrapper
        style={{pointerEvents: isLoading ? 'none' : 'auto'}}
        type={!!type}
        onClick={() => router.push(`/listings/${item.listing_key}`)}>
        <ImageWrapper>
          <Img
            img={free}
            isLoading={!!isLoading}
            src={item.main_pic || item.main_img}
          />

          {!isLoading && (
            <StatusWrapper>
              <div style={{display: 'flex', marginBottom: '8px'}}>
                <div style={{pointerEvents: 'none'}}>
                  <Status type={item.std_status_display}>
                    <Dot type={item.std_status_display} />
                    <div>
                      {item.std_status_display == 'Continuetoshow'
                        ? 'Continue To Show'
                        : item.std_status_display}
                    </div>
                  </Status>
                </div>

                <DaysOnMarketInfo>
                  {days
                    ? `${days} ${days == 1 ? 'day' : ' days'} on Offerwell`
                    : 'Added today'}
                </DaysOnMarketInfo>
              </div>
              {item.total_completed_offers && (
                <TopInfo>
                  {item.total_completed_offers}{' '}
                  {item.total_completed_offers == 1 ? 'offer' : 'offers'}
                </TopInfo>
              )}
            </StatusWrapper>
          )}
        </ImageWrapper>
        <InfoWrapper>
          <MainInfo>
            {!is_agent_profile && (
              <AgentName isLoading={!!isLoading}>
                {' '}
                {!!item.agent_name ? `${item.agent_name}` : 'g'}
              </AgentName>
            )}
            <StreetText isLoading={!!isLoading}>
              {isLoading ? 'f' : item.full_address}
            </StreetText>
            <PriceWrapper>
              <PriceTypeWrapper isLoading={!!isLoading}>
                <PriceSubtitle isLoading={!!isLoading}>
                  {' '}
                  Asking price{' '}
                </PriceSubtitle>
                <Price isLoading={!!isLoading}>
                  {formatPrice(item.price.toString())}
                </Price>
              </PriceTypeWrapper>

              <PriceTypeWrapper isLoading={!!isLoading}>
                <PriceSubtitle
                  isLoading={!!isLoading}
                  error={
                    new Date(Date.now()).getTime() >
                    new Date(item.deadline_datetime || '').getTime()
                  }>
                  Offer Deadline{' '}
                </PriceSubtitle>

                <Price isLoading={!!isLoading}>
                  {' '}
                  {item.deadline_datetime
                    ? new Date(item.deadline_datetime).toLocaleString('en', {
                        month: 'short',
                        day: 'numeric',
                        hour: 'numeric',
                        minute: 'numeric',
                      })
                    : 'No Deadline'}
                </Price>
              </PriceTypeWrapper>
            </PriceWrapper>
          </MainInfo>

          <BrokerInfo isLoading={!!isLoading}>
            MLS ID #{item.listing_id},{' '}
            {item.broker_name ? item.broker_name : '-'}
          </BrokerInfo>

          {isLoading ? (
            <AnimationBlock isLoading={isLoading} />
          ) : (
            <ButtonsWrapper>
              {showingsIsActive() && (
                <>
                  <SignedIn>
                    <Button
                    variant="secondary_mono_dark"
                    fullWidth
                    onClick={(e) => {
                      e.preventDefault()
                      router.push(`/schedule-showing/${item.listing_key}`)
                    }}>
                      Book showing
                    </Button>
                  </SignedIn>
                  <SignedOut>
                    <SignUpButton mode='modal'>
                      <Button
                      variant="secondary_mono_dark"
                      fullWidth>
                        Book showing
                      </Button>
                    </SignUpButton>
                  </SignedOut>
                </>



              )}
              <Button
                fullWidth
                disabled={
                  item.std_status_display == 'Pending' ||
                  item.std_status_display == 'Closed' ||
                  !item.is_accepting_offers
                }
                onClick={(event) => {
                  event.stopPropagation();
                  router.push(`/start-offer/${item?.listing_key}`);
                }}>
                Make Offer
              </Button>
            </ButtonsWrapper>
          )}
        </InfoWrapper>
      </Wrapper>
      {signOutModal && (
        <SignOutUserModal
          open={signOutModal}
          onOpenChange={() => setSignOutModal(false)}
        />
      )}
    </>
  );
};
