import useUpdateListingRequest from '@/features/listings/hooks/useUpdateListingRequest';
import {RequestItem} from '@/features/listings/types';

export const useData = (item: RequestItem) => {
  const {mutate: updateListingRequestMutate} = useUpdateListingRequest();

  const onRespond = (agree: boolean) => {
    return updateListingRequestMutate({
      id: item.id,
      status: agree ? 2 : 3,
    });
  };

  return {onRespond};
};
