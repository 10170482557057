import {FontWeights} from '@/themes';
import {ButtonKeys} from '@/themes/palletes/types';
import React, {AnchorHTMLAttributes, FC, ReactNode, useMemo} from 'react';
import {Container, IconWrapper, Text, Wrapper} from './Button.styles';

type TProps = AnchorHTMLAttributes<HTMLAnchorElement> & {
  margins?: {
    top?: string;
    left?: string;
    right?: string;
    bottom?: string;
  };
  color?: keyof ButtonKeys;
  size?: 'default' | 'small' | 'big' | 'medium';
  backgroundType?: 'outline' | 'default';
  variant?: 'square' | 'round';
  width?: string;
  weight?: keyof typeof FontWeights;
  fontSize?: number;
  align?: 'center' | 'left' | 'right';
  rightIcon?: ReactNode;
  leftIcon?: ReactNode;
  isDisabled?: boolean;
};

export const Button: FC<TProps> = ({
  children,
  onClick = () => {},
  variant = 'square',
  size = 'default',
  color = 'light_gray',
  backgroundType = 'default',
  width,
  margins,
  align = 'center',
  fontSize = 14,
  weight = 'bold',
  rightIcon,
  leftIcon,
  isDisabled,
  ...props
}) => {
  const margin = useMemo(
    () => ({
      top: margins?.top || '0',
      bottom: margins?.bottom || '0',
      right: margins?.right || '0',
      left: margins?.left || '0',
    }),
    [margins],
  );

  const height = useMemo(() => {
    switch (size) {
      case 'small':
        return 33;
      case 'medium':
        return 44;
      case 'big':
        return 54;
      case 'default':
        return 44;
    }
  }, [size]);

  const borderRadius = useMemo(() => {
    switch (variant) {
      case 'square':
        return 40;
      case 'round':
        return 40;
    }
  }, [variant]);

  return (
    <Wrapper
      {...props}
      color={color}
      borderRadius={borderRadius}
      height={height}
      margin={margin}
      type={backgroundType}
      width={width}
      isDisabled={!!isDisabled}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}>
      <Container>
        {leftIcon && <IconWrapper left>{leftIcon}</IconWrapper>}
        <Text
          align={align}
          fontSize={fontSize}
          color={color}
          weight={weight}
          type={backgroundType}>
          {children}
        </Text>

        {rightIcon && <IconWrapper>{rightIcon}</IconWrapper>}
      </Container>
    </Wrapper>
  );
};
