import {useCallback} from 'react';
import {FC} from 'react';
import {
  AdditionalInfo,
  AdditionalInfoWrapper,
  AgentInfo,
  BottomInfo,
  ButtonWrapper,
  Dot,
  Img,
  Status,
  Title,
  Wrapper,
} from './ListRequestCard.styles';
import {useData} from './useData';
import {RequestItem} from '@/features/listings/types';
import {Button} from '@/components/Core/Button';
import Text from '@/components/ui/Text';

type TProps = {
  item: RequestItem;
  isLoading?: boolean;
};
export const ListRequestCard: FC<TProps> = ({item, isLoading}) => {
  const {onRespond} = useData(item);

  const status = useCallback(() => {
    switch (item.prop.std_status_display) {
      case 'Pending':
        return 4;
      case 'Withdrawn':
        return 3;
      case 'Closed':
        return 2;
      default:
        return 1;
    }
  }, [item.prop.std_status_display]);

  return (
    <Wrapper>
      <AdditionalInfoWrapper>
        <Img src={item.prop.main_pic || item.prop.main_img} />
        <AdditionalInfo>
          <Title isLoading={!!isLoading}>
            {isLoading ? 'skeleton' : item.prop.full_address}
          </Title>
          <BottomInfo>
            <div style={{pointerEvents: 'none'}}>
              <Status type={status()} complete isLoading={false}>
                {!isLoading && <Dot type={status()} complete />}
                <div>{item.prop.std_status_display}</div>
              </Status>
            </div>
          </BottomInfo>
        </AdditionalInfo>
      </AdditionalInfoWrapper>
      <ButtonWrapper>
        {' '}
        <AgentInfo>
          <span>{item.prop.agent_name}</span> has invited you to{' '}
          <Text weight="bold">{item.role == 4 ? 'follow' : 'manage'}</Text> this
          listing.
        </AgentInfo>
        <Button
          width="93px"
          margins={{right: '8px', left: '16px'}}
          color="transparent"
          onClick={() => onRespond(false)}>
          Decline
        </Button>
        <Button color="gold" width="93px" onClick={() => onRespond(true)}>
          Accept
        </Button>
      </ButtonWrapper>
    </Wrapper>
  );
};
