import {MastercardIcon, VisaIcon} from '@/components/Icons';
import {TPaymentProps} from '@/constants';
import {FC, useCallback} from 'react';
import {
  Action,
  BottomActions,
  HeaderInfo,
  HeaderTitle,
  PaymentHeader,
  Wrapper,
} from './PaymentCard.styles';

type TProps = {
  item: TPaymentProps;
  onDelete: () => () => void;
  onEdit: () => () => void;
};

export const PaymentCard: FC<TProps> = ({item}) => {
  const renderIcon = useCallback(() => {
    switch (item.type) {
      case 'Visa':
        return <VisaIcon />;
      case 'Master':
        return <MastercardIcon />;
      default:
        return <VisaIcon />;
    }
  }, [item.type]);

  return (
    <Wrapper>
      <PaymentHeader>
        {renderIcon()}
        <HeaderInfo>
          <HeaderTitle>{`${item.name} ending in ${item.end_of}`}</HeaderTitle>
          <div>{`Expires ${item.expires}`}</div>
        </HeaderInfo>
      </PaymentHeader>
      <BottomActions>
        {!item.isDefault && <Action>Set as Default</Action>}
        <Action>Edit</Action>
        <Action>Delete</Action>
      </BottomActions>
    </Wrapper>
  );
};
