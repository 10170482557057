import axios from '@/services/axios';
import {UpdateListingRequestParams} from '../types';

const updateListingRequest = (data: UpdateListingRequestParams) => {
  return axios({
    url: `/properties/requests/${data.id}/`,
    method: 'put',
    data,
  });
};

export default updateListingRequest;
