import {TColorsBorder, TextColors} from '@/themes';
import styled from 'styled-components';

type TProps = {
  border?: keyof TColorsBorder;
  type: boolean;
};
type LProps = {isLoading: boolean};
export const Animation = styled.div<LProps>`
  border-radius: 16px;

  @keyframes skeleton-loading {
    0% {
      background-color: ${({theme}) => theme.new_brand.monochrome_90};
    }

    100% {
      background-color: ${({theme}) => theme.new_brand.monochrome_80};
    }
  }

  animation: ${({isLoading}) => (isLoading ? 'skeleton-loading' : '')} 1s linear
    infinite alternate;
`;
export const Wrapper = styled.div<TProps>`
  display: flex;
  flex-direction: column;
  background-color: ${({theme}) => theme.new_brand.monochrome_100};
  border: 1px solid ${({theme}) => theme.new_brand.monochrome_75};

  &:hover {
    transform: scale(1.02) translate(0, 2px);
    z-index: 2;
  }

  transition:
    box-shadow 0.2s,
    transform 0.2s;
  border-radius: 16px;
  cursor: pointer;
  position: relative;
`;

export const Img = styled.img<{img: string; isLoading: boolean}>`
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 16px 16px 0 0;
  background-image: url(${({img}) => img});
  background-position: center;
  background-size: cover;
`;
export const InfoWrapper = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 180px;
`;

export const MainInfo = styled.div`
  padding-bottom: 12px;
`;

export const StreetText = styled(Animation)`
  font-weight: 700;
  line-height: 24px;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 95%;
  white-space: nowrap;
  color: ${({isLoading}) => (isLoading ? 'transparent' : '')};
`;

export const OfferPrice = styled.div<{color?: keyof TextColors}>`
  font-weight: 700;
  font-size: 20px;
`;

export const ButtonWrapper = styled.div`
  padding-top: 16px;
  display: flex;
  justify-content: space-between;
`;
export const AdditionalSubtitle = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${({theme}) => theme.new_brand.monochrome_50};
`;

export const Icons = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  & svg {
    cursor: pointer;
  }
`;
export const ImageWrapper = styled.div`
  height: 225px;
  width: 100%;
  border-radius: 16px 16px 0 0;
  position: relative;
  overflow: hidden;
`;
export const StatusWrapper = styled.div`
  position: absolute;
  top: 24px;
  left: 24px;
  right: 24px;
  flex-wrap: wrap;
  display: flex;
  align-items: flex-start;
`;

export const GhostButton = styled.div`
  & a:last-child {
    background-color: #fff;
  }
`;
export const DotsButton = styled.div`
  border: 1px solid ${({theme}) => theme.new_brand.monochrome_75};
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 16px;
  margin-right: 8px;
  max-height: 44px;
`;

export const Price = styled(Animation)`
  font-weight: 700;
  font-size: 20px;
  color: ${({isLoading}) => (isLoading ? 'transparent' : '')};
  ${({theme}) => theme.mediaQueriesMax.lg} {
    font-size: 16px;
  }
`;

export const Tooltip = styled.span`
  display: none;
  position: absolute;
  border-radius: 6px;
  border: 1px solid ${({theme}) => theme.border.default};
  padding: 4px;
  background-color: ${({theme}) => theme.new_brand.monochrome_100};
  top: 32px;
  white-space: nowrap;
`;

export const Dot = styled.div<{type: number; complete: boolean}>`
  width: 7px;
  height: 7px;
  margin-right: 8px;
  border-radius: 100%;
  background-color: ${({theme, type, complete}) =>
    theme.new_brand[
      !complete
        ? 'monochrome_5'
        : type == 1
          ? 'brand_green'
          : type == 4
            ? 'neutral_0'
            : 'monochrome_5'
    ]};
`;
export const Status = styled.div<{type: number; complete: boolean}>`
  background-color: ${({theme, type, complete}) =>
    theme.new_brand[
      !complete
        ? 'monochrome_95'
        : type == 1
          ? 'brand_yellow'
          : type == 4
            ? 'neutral_80'
            : 'primary_10'
    ]};

  & > div {
    color: ${({theme, type, complete}) =>
      theme.new_brand[
        !complete
          ? 'monochrome_5'
          : type == 1
            ? 'brand_green'
            : type == 4
              ? 'neutral_0'
              : 'monochrome_5'
      ]};
  }

  padding: 8px 16px;
  border-radius: 40px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
`;

export const AgentInfo = styled.div`
  line-height: 140%;
  max-width: 300px;

  & > span:first-child {
    color: ${({theme}) => theme.new_brand.neutral_20};
  }
`;
