import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: ${({theme}) => theme.new_brand.monochrome_100};
  padding: 16px;
  border: 1px solid ${({theme}) => theme.new_brand.monochrome_50};
  border-radius: 6px;
  width: 240px;

  &:hover {
    background-color: ${({theme}) => theme.new_brand.monochrome_90};
  }

  margin-bottom: 24px;
`;

export const PaymentHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
`;

export const HeaderInfo = styled.div`
  font-size: 12px;
  margin-left: 8px;
`;

export const HeaderTitle = styled.div`
  font-weight: 700;
`;

export const BottomActions = styled.div`
  text-decoration: none;
  color: ${({theme}) => theme.text.default};
  font-weight: 700;
  font-size: 12px;
  display: flex;
`;

export const Action = styled.a`
  margin-right: 16px;
`;
