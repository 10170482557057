import {TColorsBorder} from '@/themes';
import styled from 'styled-components';

type TProps = {
  border?: keyof TColorsBorder;
  opacity?: number;
};
type LProps = {isLoading: boolean};

export const Animation = styled.div<LProps>`
  border-radius: 16px;

  @keyframes skeleton-loading {
    0% {
      background-color: ${({theme}) => theme.new_brand.monochrome_90};
    }

    100% {
      background-color: ${({theme}) => theme.new_brand.monochrome_80};
    }
  }

  animation: ${({isLoading}) => (isLoading ? 'skeleton-loading' : '')} 1s linear
    infinite alternate;
`;
export const Wrapper = styled.div<TProps>`
  height: 108px;
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 16px;
  margin-bottom: 16px;
  border: 1px solid ${({theme}) => theme.new_brand.monochrome_75};

  &:hover {
    z-index: 2;
    transform: scale(1.02) translate(0, 2px);
  }

  transition:
    box-shadow 0.2s,
    transform 0.2s,
    opacity 1s;

  & span {
    font-size: 14px;
  }

  & a {
    padding: 8px 16px;
  }

  cursor: pointer;
  position: relative;
  opacity: ${({opacity}) => opacity};
`;

export const MainInfoWrapper = styled.div`
  display: flex;
`;
export const AdditionalInfoWrapper = styled.div`
  display: flex;
  height: 100%;
`;

export const MainInfo = styled.div`
  display: flex;
  align-items: center;
`;

export const BottomInfo = styled.div`
  display: flex;
`;

export const AdditionalInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const AdditionalSubtitle = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: ${({theme}) => theme.new_brand.monochrome_50};
`;

export const AdditionalWrapper = styled.div`
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  margin-left: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:hover {
    & > span {
      display: flex;
    }
  }

  position: relative;
`;
export const Img = styled.img`
  width: 64px;
  height: 64px;
  border-radius: 12px;
  margin-right: 32px;

  @keyframes skeleton-loading {
    0% {
      background-color: ${({theme}) => theme.new_brand.monochrome_90};
    }

    100% {
      background-color: ${({theme}) => theme.new_brand.monochrome_80};
    }
  }

  animation: skeleton-loading 1s linear 8 alternate;
`;
export const Title = styled(Animation)`
  font-weight: 700;
  font-size: 16px;
  color: ${({isLoading}) => (isLoading ? 'transparent' : '')};
  width: ${({isLoading}) => isLoading && '400px'};
`;

export const Dot = styled.div<{type: number; complete: boolean}>`
  width: 7px;
  height: 7px;
  margin-right: 8px;
  border-radius: 100%;
  background-color: ${({theme, type, complete}) =>
    theme.new_brand[
      !complete
        ? 'monochrome_5'
        : type == 1
          ? 'brand_green'
          : type == 4
            ? 'neutral_0'
            : 'monochrome_5'
    ]};
`;
export const Status = styled.div<{
  type: number;
  complete: boolean;
  isLoading: boolean;
}>`
  background-color: ${({theme, type, complete}) =>
    theme.new_brand[
      !complete
        ? 'monochrome_95'
        : type == 1
          ? 'brand_yellow'
          : type == 4
            ? 'neutral_80'
            : 'primary_10'
    ]};

  & > div {
    color: ${({theme, type, complete, isLoading}) =>
      isLoading
        ? 'transparent'
        : theme.new_brand[
            !complete
              ? 'monochrome_5'
              : type == 1
                ? 'brand_green'
                : type == 4
                  ? 'neutral_0'
                  : 'monochrome_5'
          ]};
  }

  padding: 8px 16px;
  border-radius: 40px;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;

  @keyframes skeleton-loading {
    0% {
      background-color: ${({theme}) => theme.new_brand.monochrome_90};
    }

    100% {
      background-color: ${({theme}) => theme.new_brand.monochrome_80};
    }
  }

  animation: ${({isLoading}) => (isLoading ? 'skeleton-loading' : '')} 1s linear
    infinite alternate;
`;
export const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
`;
export const AgentInfo = styled.div`
  line-height: 140%;

  & > span:first-child {
    color: ${({theme}) => theme.new_brand.neutral_20};
    font-size: 16px;
  }
`;
