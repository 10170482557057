import {useMutation, useQueryClient} from '@tanstack/react-query';

import {UpdateListingRequestParams} from '../types';
import updateListingRequest from '../services/updateListingRequest';

const useUpdateListingRequest = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: UpdateListingRequestParams) =>
      updateListingRequest(data),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ['listings'],
      });
    },
  });
};

export default useUpdateListingRequest;
